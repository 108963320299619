import React from "react"
import { graphql, Link } from "gatsby"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import AppCta from "../components/appCta"
import BackToHome from "../components/back-to-home"
import ModalVideo from "react-modal-video"
import BackgroundImage from "gatsby-background-image"
import ImageMeta from "../components/ImageMeta"

class Video extends React.Component {
  state = { videoOpen: false }

  openVideo = e => {
    if (e) {
      e.preventDefault()
    }

    this.setState({ videoOpen: true })
  }

  closeVideo = () => {
    this.setState({ videoOpen: false })
  }

  render() {
    const { videoId, title, localThumbnail } = this.props

    return (
      <div className="card shadow-sm" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
        <div className={"card-body text-left"}>
          <h5 className="mt-20 mb-15 title">{title}</h5>
        </div>
        <BackgroundImage Tag="div" className={"card-img video centered-container"} fluid={localThumbnail.childImageSharp.fluid} style={{ height: "300px" }}>
          <AppCta onClick={this.openVideo} centered />
          <ModalVideo channel="youtube" isOpen={this.state.videoOpen} videoId={videoId} onClose={this.closeVideo} />
        </BackgroundImage>
      </div>
    )
  }
}

const WebinarPage = ({ data }) => (
  <LayoutPage>
    <SEO title={"Webinars"} meta={ImageMeta} />
    <section className="showcase_2 bg-light page-padding pb-90 text-center">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              Bi-Weekly Webinars
            </h2>
            <div className="mt-15 mb-25 f-22 color-heading text-adaptive" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
              If you want to meet <Link to={"/team"}>the team</Link> in a live setting, the webinars are for you. We organize them every two weeks on Wednesdays, 15:00 CET time.
              They are streamed on our YouTube channel and of course archived on a{" "}
              <a href={"https://www.youtube.com/playlist?list=PLgGwfN_nIr4gPtC5DnZQOzsNOynXD-4fN"}>YouTube playlist</a>. You can also have a look at them below.
            </div>
          </div>
        </div>
        <div className="row">
          <div className={"card-columns"}>
            {data.videos.nodes.map(v => (
              <Video key={v.id} {...v} />
            ))}
          </div>
        </div>
        <BackToHome />
      </div>
    </section>
  </LayoutPage>
)

export default WebinarPage

export const query = graphql`
  query {
    videos: allYoutubeVideo(sort: { order: [DESC], fields: [publishedAt] }, filter: { title: { regex: "/webinar/i" } }) {
      nodes {
        id
        title
        videoId
        localThumbnail {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
